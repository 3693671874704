export default {
  // baseURL: 'http://localhost:99',
  // webBaseURL: 'http://localhost:81/im',
  // imURL: 'http://localhost:81',
  // imBaseURL: 'localhost',
  // webHistory: '/im/',
  // wsProtocol: 'ws',
  // wsPort: '9324',

  baseURL: 'https://eean.cn/api',
  webBaseURL: 'http://chat.eean.cn/',
  imURL: 'http://chat.eean.cn/',
  imBaseURL: 'db.jsmao.cn',
  webHistory: '',
  wsProtocol: 'ws',
  wsPort: '9324',

  // baseURL: 'https://poetize.cn/api',
  // webBaseURL: 'https://poetize.cn',
  // imURL: 'https://poetize.cn/im',
  // imBaseURL: 'poetize.cn',
  // webHistory: '/im/',
  // wsProtocol: 'wss',
  // wsPort: '',

  hitokoto: 'https://v1.hitokoto.cn',
  jinrishici: 'https://v1.jinrishici.com/all.json',
  jitang: 'https://api.oick.cn/dutang/api.php',
  shehui: 'https://api.oick.cn/yulu/api.php',
  yiyan: 'https://api.oick.cn/yiyan/api.php',
  dog: 'https://api.oick.cn/dog/api.php',

  //前后端定义的密钥，AES使用16位
  cryptojs_key: 'aoligeimeimaobin',

  qiniuUrl: 'https://upload-z2.qiniup.com',
  qiniuDownload: 'https://oss.eean.cn/',

  before_color_1: 'black',
  after_color_1: 'linear-gradient(45deg, #f43f3b, #ec008c)',

  before_color_2: 'rgb(131, 123, 199)',
  after_color_2: 'linear-gradient(45deg, #f43f3b, #ec008c)',

  tree_hole_color: ['#ee7752', '#e73c7e', '#23a6d5', '#23d5ab', 'rgb(131, 123, 199)', '#23d5ab'],

  userId: 1,
  source: 0,

  emojiList: ['衰', '鄙视', '再见', '捂嘴', '摸鱼', '奋斗', '白眼', '可怜', '皱眉', '鼓掌', '烦恼', '吐舌', '挖鼻', '委屈', '滑稽', '啊这', '生气', '害羞', '晕', '好色', '流泪', '吐血', '微笑', '酷', '坏笑', '吓', '大兵', '哭笑', '困', '呲牙']
}
